import VIOLATION_TYPE from "../enums/violation-type.json";

import briberyImg from "../src/assets/images/bribery.svg";
import advertisingImg from "../src/assets/images/tv-person.svg";
import commissionImg from "../src/assets/images/commission.svg";
import otherImg from "../src/assets/images/other.svg";

export const VIOLATION_ICONS = {
  [VIOLATION_TYPE.BRIBERY]: briberyImg,
  [VIOLATION_TYPE.ADVERTISING]: advertisingImg,
  [VIOLATION_TYPE.COMMISSION]: commissionImg,
  [VIOLATION_TYPE.OTHER]: otherImg
};
